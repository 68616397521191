.backdrop {
    position: fixed;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    background: rgba(0,0,0,0.3);
    z-index: 100;
}
/*----------------------side-drawer-------------------------------------*/
.side-drawer {
    min-height: 100%;
    /* max-height: 100vh; */
    background: #cdc9c3;
    box-shadow: 1px 0px 7px rgba(0, 0, 0, 0.5);
    position: fixed;
    top: 0;
    left: 0;
    width: 70%;
    /* max-width: 400px; */
    z-index: 200;
    transform: translateX(-100%);
    transition: transform 0.3s ease-out;
  }
  
  .side-drawer.open {
    transform: translateX(0);
    width:200px;
    /* height: 100%; */
    /* position: fixed; */
  }
  
  .side-drawer ul {
    height: 100%;
    list-style: none;
    display: flex;
    flex-direction: column;
    justify-content: center;
    /* margin: 0; */
    /* padding: 0; */
    position: absolute;
    bottom: 0px;
  }
  
  .side-drawer li {
    margin: 0.5rem  0 50px;
  }
  
  .side-drawer a {
    color: #7d5a5a;
    text-decoration: none;
    font-size: 1.2rem;
  }
  
  .side-drawer a:hover,
  .side-drawer a:active {
    color: black;
  }
  
  @media (min-width: 769px) {
      .side-drawer {
          display: none;
      }
  }
  /*------------------------toggle-button-----------------------------------------*/
  .toggle-button {
    display: flex;
    flex-direction: column;
    justify-content: space-around;
    height: 24px;
    width: 30px;
    background: transparent;
    border: none;
    cursor: pointer;
    padding: 0;
    box-sizing: border-box;
}

.toggle-button:focus {
    outline: none;
    transform: rotate(30deg);
}

.toggle-button__line {
    width: 30px;
    height: 2px;
    background: white;
}

  /*-----------------------toolbar------------------------------------------------*/
  .toolbar {
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    background: #7d5a5a;
    height: 56px;
 
  }
  
  .toolbar__navigation {
    display: flex;
    height: 100%;
    align-items: center;
    padding: 0 1rem;
  }
  
  .toolbar__logo {
    margin-left: 1rem;
  }

  .toolbar__logo :hover {
   font-size: 2rem;
  }

  .toolbar__logo a {
    color: white;
    text-decoration: none;
    font-size: 1.5rem;
  }
  
  .spacer {
    flex: 1;
  }
  
  .toolbar_navigation-items ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
  }
  
  .toolbar_navigation-items li {
    padding: 0 0.5rem;
  }
  
  .toolbar_navigation-items a {
    color: white;
    text-decoration: none;
  }
  
  .toolbar_navigation-items a:hover,
  .toolbar_navigation-items a:active {
    color: black;
  }
    /*-----------------------@media------------------------------------------*/
  @media (max-width: 768px) {
    .toolbar_navigation-items {
      display: none;
    }
  }
  
  @media (min-width: 769px) {
    .toolbar__toggle-button {
      display: none;
    }
    .toolbar__logo {
      margin-left: 0;
    }
  }
  /*-----------------------------------------------------------------*/

  .ContactMenu{
    position: absolute;
    bottom: 0;
    cursor:pointer;
    justify-content: center;
    /* position: fixed;
    top:85%; */
 
    left: 0;
    /* background-color: #373a47;
    opacity: 0.7; */
  }